import { ApiResponse } from '@/apis';
import { SiteData } from '@/apis/contentApi';
import { Currency } from '@/core';
import { Localisation } from '@/core/Localisation';

import { call, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { isBot, isPreRender } from './../../utils/config';
import { LocalisationApi } from '../../apis/localisationApi';
import { getSiteData } from '../../apis/contentApi.v2';
import {
    requestSiteDataSuccess,
    requestSiteDataFailure,
    setCurrency,
} from '../actions/site.actions';
import { logErrorWithInfo } from '../../utils/sentry';

export function* requestSiteDataSaga() {
    try {
        if (
            !Cookies.get('TAP-SelectedCurrency') &&
            (isPreRender() || isBot())
        ) {
            yield put(setCurrency(defaultCurrency.code));
        }

        const getSiteDataResponse: SiteData = yield call(getSiteData);

        yield put(requestSiteDataSuccess(getSiteDataResponse));
    } catch (error) {
        yield put(requestSiteDataFailure(error, getBasicSiteData()));
    }
}

export function* requestLocalisationDataSaga() {
    if (!Cookies.get('TAP-SelectedCurrency')) {
        const localisationApi = new LocalisationApi();

        const localisationData: ApiResponse<Localisation> = yield call(
            localisationApi.getLocalisation
        );

        if (localisationData.isSuccess && localisationData.result) {
            yield put(setCurrency(localisationData.result.currencyCode));
        } else {
            logErrorWithInfo(localisationData.errors);
        }
    }
}

function getBasicSiteData() {
    return {
        currencies: [defaultCurrency],
        header: {
            dealsMenuTitle: 'Deals',
            dealsMenuUrl: '/deals',
            dealsLinks: [],
        },
        reviews: [],
        regions: [],
        activities: [],
        saleBanner: null,
    };
}

const defaultCurrency: Currency = {
    code: 'GBP',
    displayTelephoneNumber: '+44 (0)208 004 8886',
    displayTailorMadeTelephoneNumber: null,
    flag: 'https://imgix.cosmicjs.com/e7817ba0-ab1f-11ea-acab-c986a56b1c02-united-kingdom.svg',
    locale: 'en-GB',
    name: 'Pound Sterling',
    position: 'Left',
    space: false,
    symbol: '£',
    telephoneNumber: '+44-208-004-8886',
    telephoneNumberTailormade: null,
};
