import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import {
    FloatingContext,
    useClick,
    useDismiss,
    useFloating,
    useListNavigation,
    useInteractions,
    useTransitionStatus,
} from '@floating-ui/react';

interface DesktopMenuContextValue {
    isOpen: boolean;
    floatingContext: FloatingContext<HTMLElement> | null;
    listRef: React.MutableRefObject<Array<HTMLElement | null>>;
    onLinkClick: () => void;
    closeMenu: () => void;
    getFloatingProps: () => Record<string, any>;
    getReferenceProps: () => Record<string, any>;
    getItemProps: () => Record<string, any>;
    setReference: (ref: HTMLElement | null) => void;
    setFloating: (ref: HTMLElement | null) => void;
    selectedMenuIndex: number;
    mountedStatus: 'unmounted' | 'initial' | 'open' | 'close';
    isMounted: boolean;
}

export interface DesktopMenuProviderProps {
    children: ReactNode;
}

const initialContextValue: DesktopMenuContextValue = {
    isOpen: false,
    floatingContext: null,
    listRef: { current: [] },
    selectedMenuIndex: 0,
    onLinkClick: () => {},
    closeMenu: () => {},
    getFloatingProps: () => ({}),
    getReferenceProps: () => ({}),
    getItemProps: () => ({}),
    setReference: () => {},
    setFloating: () => {},
    mountedStatus: 'unmounted',
    isMounted: false,
};

export const DesktopMenuContext =
    createContext<DesktopMenuContextValue>(initialContextValue);

export function useDesktopMenu() {
    const context = useContext(DesktopMenuContext);
    if (!context) {
        throw new Error('No Desktop Links Context initialised.');
    }
    return context;
}

export function DesktopMenuProvider({ children }: DesktopMenuProviderProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const listRef = useRef([]);
    const { context, refs } = useFloating<HTMLElement>({
        open: isOpen,
        onOpenChange: handleOpenChange,
    });
    const { isMounted, status: mountedStatus } = useTransitionStatus(context, {
        duration: 100,
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const listNav = useListNavigation(context, {
        listRef,
        activeIndex: activeIndex,
        onNavigate: handleNavigate,
        focusItemOnHover: false,
        openOnArrowKeyDown: true,
    });
    const { getFloatingProps, getReferenceProps, getItemProps } =
        useInteractions([click, dismiss, listNav]);

    return (
        <DesktopMenuContext.Provider
            value={{
                isOpen: isOpen,
                floatingContext: context,
                onLinkClick: handleLinkClick,
                closeMenu: handleCloseMenu,
                getItemProps,
                setReference: refs.setReference,
                setFloating: refs.setFloating,
                getFloatingProps,
                getReferenceProps,
                listRef,
                selectedMenuIndex: activeIndex ?? 0,
                mountedStatus,
                isMounted,
            }}
        >
            {children}
        </DesktopMenuContext.Provider>
    );

    function handleLinkClick() {
        setIsOpen(true);
    }

    function handleCloseMenu() {
        setIsOpen(false);
    }

    function handleOpenChange(open: boolean) {
        setIsOpen(open);
    }

    function handleNavigate(activeIndex: number | null) {
        setActiveIndex(activeIndex);
    }
}
