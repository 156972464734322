import { Suggestion, SuggestionType } from '../data';

import { tourSuggestions } from '../data/tourSuggestions';
import { groupBy } from '../utils/groupBy';

export interface Section {
    title: string;
    suggestions: Suggestion[];
}

export function getSuggestions(value: string): Section[] {
    if (value.trim() !== '') {
        const sections: Section[] = [];
        const filteredSuggestions = [...tourSuggestions].filter(s =>
            s.name.toLowerCase().includes(value.trim().toLowerCase())
        );
        const popularSuggestions = filteredSuggestions.filter(s => s.isPopular);
        if (popularSuggestions.length > 0) {
            sections.push({
                title: 'Popular',
                suggestions: popularSuggestions,
            });
        }
        const otherSuggestions = filteredSuggestions.filter(s => !s.isPopular);
        groupBy(otherSuggestions, s => s.type).forEach(g =>
            sections.push({
                title: SuggestionType[g.key],
                suggestions: g.values,
            })
        );
        return sections;
    } else {
        return [];
    }
}
