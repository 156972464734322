import { AgentsResponse, TradeAgentSignUp } from '@/endurance/agents';
import { ApiResponse, BasicApiResponse } from '@/endurance';

import { getResponse, postResponse } from './enduranceApi';

const agentUrl = '/agents';
const tradeAgentSignUpFormUrl = '/agents/tradeagentsignup';

export async function fetchAgents() {
    return await getResponse<BasicApiResponse<AgentsResponse>>(agentUrl);
}

export async function sendTradeAgentSignUpForm(
    firstName: string,
    lastName: string,
    email: string,
    phoneCode: string,
    phoneNumber: string,
    travelAgency: string,
    branchName: string,
    abtaNumber: string
) {
    return await postResponse<TradeAgentSignUp, ApiResponse>(
        tradeAgentSignUpFormUrl,
        {
            firstName,
            lastName,
            email,
            phone: `${phoneCode}-${phoneNumber}`,
            travelAgency: travelAgency,
            branchName: branchName !== '' ? branchName : null,
            abtaNumber: abtaNumber !== '' ? abtaNumber : null,
            iataNumber: null,
            tradeBody: null,
        }
    );
}

const agentsApi = {
    fetchAgents,
    sendTradeAgentSignUpForm,
};

export default agentsApi;
