import { MenuLink } from '../MenuLink';

import classNames from 'classnames';
import DesktopMenu from '../DesktopMenu/DesktopMenu';
import { DesktopMenuProvider } from '../DesktopMenu/DesktopMenuContext';
import styles from './DealsMenu.module.scss';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { useMemo } from 'react';

export interface DealsMenuProps {
    className?: string;
}

function DealsMenu({ className }: DealsMenuProps) {
    const isLoading = useAppSelector(({ site }) => site.isLoading);

    const dealsTitle = useAppSelector(
        ({ site }) => site.header?.dealsMenuTitle ?? 'Deals'
    );
    const dealsUrl = useAppSelector(
        ({ site }) => site.header?.dealsMenuUrl ?? '/deals'
    );
    const deals = useAppSelector(({ site }) => site.header?.dealsLinks ?? []);
    const dealsImageUrl = useAppSelector(
        ({ site }) => site.dealsImageUrl ?? null
    );

    const dealLinks: MenuLink[] = useMemo(
        () => [
            ...deals.map(({ title, slug, imageUrl }) => ({
                title,
                url: `/deals/${slug}`,
                imageUrl,
                subLinks: [],
            })),
            {
                title: 'All Deals',
                url: dealsUrl,
                imageUrl: dealsImageUrl,
                subLinks: [],
            },
        ],
        [deals, dealsUrl, dealsImageUrl]
    );

    if (isLoading) {
        return null;
    }

    return (
        <DesktopMenuProvider>
            <DesktopMenu
                className={classNames(styles.dealsMenu, className)}
                links={dealLinks}
            >
                {dealsTitle}
            </DesktopMenu>
        </DesktopMenuProvider>
    );
}

export default DealsMenu;
