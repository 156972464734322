import classNames from 'classnames';
import Icon, { Icons } from '../../../../common/Icon/Icon';
import { Suggestion, SuggestionType } from '../../../../../data';
import styles from './SearchOption.module.scss';

export interface SearchOptionProps {
    suggestion: Suggestion;
    onClick: (suggestion: Suggestion) => void;
    className?: string;
}

function SearchOption({ suggestion, onClick, className }: SearchOptionProps) {
    return (
        <li className={classNames(styles.searchOption, className)}>
            <button onClick={handleClick}>
                <Icon icon={iconMappings[suggestion.type]} />
                <span>{suggestion.name}</span>
            </button>
        </li>
    );

    function handleClick() {
        onClick(suggestion);
    }
}

const iconMappings: { [key in SuggestionType]: keyof Icons } = {
    [SuggestionType.Anywhere]: 'mapMarker',
    [SuggestionType.Country]: 'mapMarker',
    [SuggestionType.Destination]: 'mapMarker',
    [SuggestionType.Landmark]: 'landmark',
    [SuggestionType.Peak]: 'mountain',
    [SuggestionType.Region]: 'globe',
};

export default SearchOption;
