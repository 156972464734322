import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import styles from './FavouritesButton.module.scss';

export interface FavouritesButtonProps {
    className?: string;
}

function FavouritesButton({ className }: FavouritesButtonProps) {
    return (
        <CircularIconButton
            className={classNames(styles.favouritesButton, className)}
            icon="emptyHeart"
            as={Link}
            to="/favourites"
            aria-label="View favourites"
            onClick={handleClick}
            title="Favourites"
        />
    );

    function handleClick() {
        sendEventV2(
            Page.header,
            Area.favourites,
            InteractionItem.favourites,
            Action.click,
            null
        );
    }
}

export default FavouritesButton;
