import { createContext, ReactNode, useContext, useState } from 'react';

interface HeaderContextValue {
    isMobileMenuOpen: boolean;
    setIsMobileMenuOpen: (value: boolean) => void;
    isMobileSearchOpen: boolean;
    setIsMobileSearchOpen: (value: boolean) => void;
}

export interface HeaderProviderProps {
    children: ReactNode;
}

const initialContextValue: HeaderContextValue = {
    isMobileMenuOpen: false,
    setIsMobileMenuOpen: () => {},
    isMobileSearchOpen: false,
    setIsMobileSearchOpen: () => {},
};

export const HeaderContext =
    createContext<HeaderContextValue>(initialContextValue);

export function useHeader() {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error('No Header Context initialised.');
    }
    return context;
}

export function HeaderProvider({ children }: HeaderProviderProps) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

    return (
        <HeaderContext.Provider
            value={{
                isMobileMenuOpen,
                setIsMobileMenuOpen,
                isMobileSearchOpen,
                setIsMobileSearchOpen,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
}
