import classNames from 'classnames';
import Icon from '../../../common/Icon/Icon';
import styles from './MobileMenuButton.module.scss';
import { useHeader } from '../HeaderContext.v2';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';

export interface MobileMenuButtonProps {
    className?: string;
}

function MobileMenuButton({ className }: MobileMenuButtonProps) {
    const { setIsMobileMenuOpen } = useHeader();
    return (
        <button
            className={classNames(styles.mobileMenuButton, className)}
            aria-label="Open menu"
            onClick={handleClick}
        >
            <Icon icon="menu" />
        </button>
    );

    function handleClick() {
        setIsMobileMenuOpen(true);
        sendEventV2(
            Page.header,
            Area.menuButton,
            InteractionItem.menuButton,
            Action.open,
            null
        );
    }
}

export default MobileMenuButton;
