import classNames from 'classnames';
import Icon from '../../../../common/Icon/Icon';
import styles from './PhoneLink.module.scss';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { selectTapTelephoneNumber } from '../../../../../redux/selectors/site.selectors';

export interface PhoneLinkProps {
    className?: string;
}

function PhoneLink({ className }: PhoneLinkProps) {
    const displayTelephoneNumber = useAppSelector(({ site }) => {
        const { displayTelephoneNumber } = selectTapTelephoneNumber(site);
        return displayTelephoneNumber;
    });
    const telephoneNumber = useAppSelector(({ site }) => {
        const { telephoneNumber } = selectTapTelephoneNumber(site);
        return telephoneNumber;
    });
    return (
        <a
            className={classNames(styles.phoneLink, className)}
            href={`tel:${telephoneNumber}`}
        >
            <Icon icon="phone" />
            {displayTelephoneNumber}
        </a>
    );
}

export default PhoneLink;
