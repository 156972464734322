import { AgentsState } from '@/redux/AgentsState';
import {
    FetchAgentFailureAction,
    FetchAgentSuccessAction,
} from '@/redux/actions/agents';
import {
    FETCH_AGENTS_REQUEST,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_FAILURE,
} from '../actions/types';
import { Action } from '@/redux/actions/Action';

const initialState: AgentsState = {
    loading: null,
    agents: null,
    error: null,
};

const fetchAgentsRequest = (state: AgentsState) => ({
    ...state,
    loading: true,
});
const fetchAgentsSuccess = (
    state: AgentsState,
    action: FetchAgentSuccessAction
) => ({
    ...state,
    loading: false,
    agents: action.agents,
});

const fetchAgentsFailure = (
    state: AgentsState,
    action: FetchAgentFailureAction
) => ({
    ...state,
    loading: false,
    agents: [],
    error: action.error,
});

const agentReducer = (state = initialState, action: Action) => {
    const handlers: any = {
        [FETCH_AGENTS_REQUEST]: fetchAgentsRequest,
        [FETCH_AGENTS_SUCCESS]: fetchAgentsSuccess,
        [FETCH_AGENTS_FAILURE]: fetchAgentsFailure,
    };

    const handler: (site: AgentsState, action?: Action) => AgentsState =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default agentReducer;
