import { Link } from 'react-router-dom';
import MobileMenuButton from './MobileMenuButton/MobileMenuButton';
import MyAccountButton from './MyAccountButton/MyAccountButton';
import MobileSearchButton from './MobileSearchButton/MobileSearchButton';
import MobilePhoneButton from './MobilePhoneButton/MobilePhoneButton';
import FavouritesButton from './FavouritesButton/FavouritesButton';
import DesktopPhoneButton from './DesktopPhoneButton/DesktopPhoneButton';
import DesktopCurrencySelector from './DesktopCurrencySelector/DesktopCurrencySelector';
import DesktopLinks from './DesktopLinks/DesktopLinks';
import DesktopSearchTool from './DesktopSearchTool/DesktopSearchTool';
import MobileMenu from './MobileMenu/MobileMenu';
import MobileSearchMenu from './MobileSearchMenu/MobileSearchMenu';
import { sendEventV2 } from '../../../utils/analytics/analyticsService';
import { desktopHeight, mobileHeight } from './Header.v2.constants';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../utils/analytics/enums';
import logoImage from '../../../assets/images/the-adventure-people-logo-full.svg';
import styles from './Header.v2.module.scss';

export interface HeaderV2Props {}

function HeaderV2() {
    return (
        <>
            <header
                className={styles.header}
                style={
                    {
                        '--mobile-height': `${mobileHeight}px`,
                        '--desktop-height': `${desktopHeight}px`,
                    } as React.CSSProperties
                }
            >
                <nav className={styles.inner}>
                    <Link
                        to="/"
                        className={styles.home}
                        onClick={handleBrandClick}
                    >
                        <img
                            loading="eager"
                            fetchpriority="high"
                            alt="Home"
                            src={logoImage}
                        />
                    </Link>

                    <DesktopSearchTool className={styles.desktopSearchTool} />

                    <DesktopLinks className={styles.desktopLinks} />

                    <div className={styles.buttons}>
                        <MobileSearchButton />
                        <MobilePhoneButton />
                        <DesktopPhoneButton />
                        <FavouritesButton />
                        <MyAccountButton />
                        <DesktopCurrencySelector />
                        <MobileMenuButton className={styles.mobileMenuButton} />
                    </div>
                </nav>
            </header>
            <div id="header-floating-menu" />
            <MobileMenu />
            <MobileSearchMenu />
        </>
    );

    function handleBrandClick() {
        sendEventV2(
            Page.header,
            Area.homeLink,
            InteractionItem.homeLink,
            Action.click,
            null
        );
    }
}

export default HeaderV2;
