import { MenuLink } from '../MenuLink';
import { Region } from '@/apis/contentApi';

import { useMemo } from 'react';
import classNames from 'classnames';
import DesktopMenu from '../DesktopMenu/DesktopMenu';
import { DesktopMenuProvider } from '../DesktopMenu/DesktopMenuContext';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import styles from './DestinationsMenu.module.scss';

export interface DestinationsMenuProps {
    className?: string;
}

function DestinationsMenu({ className }: DestinationsMenuProps) {
    const isLoading = useAppSelector(({ site }) => site.isLoading);
    const regions = useAppSelector(({ site }) => site?.regions ?? []);
    const popularDestinationsImageUrl = useAppSelector(
        ({ site }) => site?.popularDestinationsImageUrl ?? null
    );

    const destinationLinks: MenuLink[] = useMemo(() => {
        return [
            {
                url: '/destinations/popular',
                title: 'Popular',
                imageUrl: popularDestinationsImageUrl,
                subLinks: getPopularDestinationLinks(regions),
            },
            ...regions
                .map(
                    ({ title, slug, destinations, imageUrl }): MenuLink => ({
                        title,
                        url: `/destinations/${slug}`,
                        imageUrl: imageUrl,
                        subLinks: [
                            ...destinations
                                .map(
                                    ({ title, slug, imageUrl }): MenuLink => ({
                                        title,
                                        url: `/destinations/${slug}`,
                                        imageUrl,
                                        subLinks: [],
                                    })
                                )
                                .sort(sortByTitle),
                            {
                                title: `View all ${title}`,
                                url: `/destinations/${slug}`,
                                imageUrl: null,
                                subLinks: [],
                            },
                        ],
                    })
                )
                .sort(sortByTitle),
            {
                url: '/around-the-world',
                title: 'Around the world',
                imageUrl: null,
                subLinks: [],
            },
            {
                url: '/tailor-made',
                title: 'Tailor Made Holidays',
                imageUrl: null,
                subLinks: [],
            },
            {
                url: '/destinations',
                title: 'View all destinations',
                imageUrl: null,
                subLinks: [],
            },
        ];
    }, [regions, popularDestinationsImageUrl]);

    if (isLoading) {
        return null;
    }

    return (
        <DesktopMenuProvider>
            <DesktopMenu
                className={classNames(styles.destinationsMenu, className)}
                links={destinationLinks}
            >
                Destinations
            </DesktopMenu>
        </DesktopMenuProvider>
    );
}

function getPopularDestinationLinks(regions: Region[]) {
    const popularLinks: MenuLink[] = [];
    if (regions) {
        regions.forEach(region => {
            region.destinations.forEach(destination => {
                if (destination.isPopular) {
                    popularLinks.push({
                        url: `/destinations/${destination.slug}`,
                        title: destination.title,
                        imageUrl: destination.imageUrl,
                        subLinks: [],
                    });
                }
            });
        });
    }

    return popularLinks.sort(sortByTitle);
}

function sortByTitle(a: { title: string }, b: { title: string }) {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
}

export default DestinationsMenu;
