import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import styles from './MyAccountButton.module.scss';
import { useLogin } from '../../../../contexts/LoginContext';

export interface MyAccountButtonProps {
    className?: string;
}

function MyAccountButton({ className }: MyAccountButtonProps) {
    const { isLoggedIn } = useLogin();

    return (
        <CircularIconButton
            className={classNames(styles.myAccountButton, className)}
            as={Link}
            to="/myaccount"
            icon="userSolid"
            title={isLoggedIn ? 'My Account' : 'Sign in'}
            rel="noopener nofollow"
            onClick={handleClick}
        />
    );

    function handleClick() {}
}

export default MyAccountButton;
