import { Currency } from '@/core';

import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Option.module.scss';

export type OptionProps = {
    currency: Currency;
    isSelected: boolean;
    onSelectCurrency: (currency: Currency) => void;
    className?: string;
} & React.HTMLAttributes<HTMLElement>;

const Option = forwardRef(function (
    { currency, isSelected, onClick, onSelectCurrency, className }: OptionProps,
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <button
            ref={ref as ForwardedRef<HTMLButtonElement>}
            className={classNames(
                styles.option,
                { [styles.isSelected]: isSelected },
                className
            )}
            onClick={handleClick}
        >
            <picture className={styles.flag}>
                <img src={currency.flag} alt="" />
            </picture>
            <span className={styles.name}>{currency.name}</span>
        </button>
    );

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        onClick?.(e);
        onSelectCurrency(currency);
    }
});

export default Option;
