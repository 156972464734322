import { MenuLink } from '../MenuLink';

import { ReactNode } from 'react';
import classNames from 'classnames';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import FloatingMenu from './FloatingMenu/FloatingMenu';
import { useDesktopMenu } from './DesktopMenuContext';
import styles from './DesktopMenu.module.scss';

export interface DesktopLinkProps {
    children: ReactNode;
    links: MenuLink[];
    className?: string;
}

const portalId = 'desktop-links-portal';

function DesktopMenu({ children, links, className }: DesktopLinkProps) {
    const {
        isMounted,
        floatingContext,
        onLinkClick,
        getReferenceProps,
        setReference,
    } = useDesktopMenu();

    return (
        <li className={classNames(styles.desktopLink, className)}>
            <button
                className={styles.link}
                onClick={handleClick}
                ref={setReference}
                tabIndex={0}
                {...getReferenceProps()}
            >
                {children}
            </button>
            <FloatingPortal id={portalId}>
                {!!floatingContext && isMounted && (
                    <FloatingFocusManager context={floatingContext}>
                        <FloatingMenu links={links} />
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </li>
    );

    function handleClick() {
        onLinkClick();
    }
}

export default DesktopMenu;
