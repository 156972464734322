import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useDesktopMenu } from '../../../DesktopMenuContext';
import styles from './SubMenuLink.module.scss';

export interface SubMenuLinkProps {
    title: string;
    url: string;
    className?: string;
}

function SubMenuLink({ title, url, className }: SubMenuLinkProps) {
    const { closeMenu } = useDesktopMenu();
    const matchedRoute = useRouteMatch(url);
    const isCurrentPage = matchedRoute !== null;

    return (
        <li
            className={classNames(
                styles.subMenuLink,
                { [styles.isCurrentPage]: isCurrentPage },
                className
            )}
        >
            <Link to={url} onClick={handleClick}>
                {title}
            </Link>
        </li>
    );

    function handleClick() {
        closeMenu();
    }
}

export default SubMenuLink;
