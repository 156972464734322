import { useRef, useState } from 'react';
import classNames from 'classnames';
import {
    useInteractions,
    useListNavigation,
    autoUpdate,
    useFloating,
    useClick,
    useRole,
    useFloatingNodeId,
    FloatingFocusManager,
    FloatingPortal,
    useDismiss,
    flip,
} from '@floating-ui/react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { selectCurrency } from '../../redux/selectors/site.selectors';
import { sendEventV2 } from '../../utils/analytics/analyticsService';
import {
    Page,
    Area,
    InteractionItem,
    Action,
} from '../../utils/analytics/enums';
import { setCurrency } from '../../redux/actions';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import styles from './CurrencySelectButton.module.scss';

export interface CurrencySelectButtonProps {
    page: Page;
    className?: string;
}

function CurrencySelectButton({ page, className }: CurrencySelectButtonProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const dispatch = useAppDispatch();
    const selectedCurrency = useAppSelector(state =>
        selectCurrency(state.site)
    );
    const currencies = useAppSelector(({ site }) => site.currencies);

    const nodeId = useFloatingNodeId();
    const { floatingStyles, context } = useFloating<HTMLElement>({
        placement: 'top-end',
        open: isOpen,
        onOpenChange: handleButtonClick,
        whileElementsMounted: autoUpdate,
        nodeId,
        middleware: [flip()],
    });

    const selectedIndex = currencies?.findIndex(
        c => c.code === selectedCurrency?.code
    );
    const activeIndex = currencies?.findIndex(
        c => c.code === selectedCurrency?.code
    );

    const click = useClick(context);
    const role = useRole(context, { role: 'select' });
    const dismiss = useDismiss(context);
    const listNav = useListNavigation(context, {
        listRef: elementsRef,
        activeIndex,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([listNav, click, dismiss, role]);

    if (!selectedCurrency || (currencies?.length ?? 0) === 0) {
        return null;
    }

    return (
        <>
            <button
                className={classNames(styles.currencySelectButton, className)}
                ref={context.refs.setReference}
                onClick={() => handleButtonClick(!isOpen)}
                {...getReferenceProps()}
            >
                <label>Selected currency</label>
                <span>
                    {selectedCurrency.symbol !== selectedCurrency.code
                        ? `${selectedCurrency.symbol} ${selectedCurrency.code}`
                        : selectedCurrency.code}
                </span>
            </button>
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <ul
                            className={styles.currencyOptions}
                            id={nodeId}
                            ref={context.refs.setFloating}
                            style={floatingStyles}
                            {...getFloatingProps()}
                        >
                            {currencies.map((item, index) => (
                                <li
                                    key={item.code}
                                    className={classNames({
                                        [styles.isSelected]:
                                            index === selectedIndex,
                                    })}
                                    {...getItemProps({
                                        onClick: () =>
                                            handleSelectCurrency(index),
                                    })}
                                >
                                    <img
                                        alt=""
                                        className={styles.flag}
                                        src={item.flag}
                                    />
                                    <span className={styles.name}>
                                        {item.name}
                                    </span>
                                    <span className={styles.code}>
                                        ({item.code})
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );

    function handleButtonClick(isOpen: boolean) {
        setIsOpen(isOpen);
        if (isOpen) {
            sendEventV2(
                page,
                Area.currencySelector,
                InteractionItem.currencySelector,
                Action.open,
                null
            );
        }
    }

    function handleSelectCurrency(newIndex: number | null) {
        if (newIndex !== null) {
            const newCurrency = currencies[newIndex];
            dispatch(setCurrency(newCurrency.code));
            setIsOpen(false);
        }
    }
}

export default CurrencySelectButton;
