import { Currency } from '@/core';

import { useRef, useState } from 'react';
import classNames from 'classnames';
import {
    arrow,
    autoUpdate,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useTransitionStatus,
    useListNavigation,
} from '@floating-ui/react';
import CurrencyOption from './CurrencyOption/CurrencyOption';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { selectCurrency } from '../../../../redux/selectors/site.selectors';
import { setCurrency } from '../../../../redux/actions';
import styles from './DesktopCurrencySelector.module.scss';

export interface DesktopCurrencySelectorProps {
    className?: string;
}

const portalId = 'desktop-links-portal';
const topOffset = 8;

function DesktopCurrencySelector({ className }: DesktopCurrencySelectorProps) {
    const arrowRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLElement[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const dispatch = useAppDispatch();
    const selectedCurrency = useAppSelector(state =>
        selectCurrency(state.site)
    );
    const currencies = useAppSelector(({ site }) => site.currencies);
    const selectedIndex = selectedCurrency
        ? currencies.findIndex(c => c.code === selectedCurrency.code)
        : null;
    const { refs, context, floatingStyles, middlewareData } = useFloating({
        open: isOpen,
        onOpenChange: handleOpenChange,
        strategy: 'absolute',
        placement: 'bottom-end',
        middleware: [
            offset(topOffset),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const click = useClick(context, {});
    const dismiss = useDismiss(context, {});
    const role = useRole(context, { role: 'listbox' });
    const listNavigation = useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex,
        focusItemOnHover: false,
        allowEscape: true,
        openOnArrowKeyDown: true,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([click, dismiss, role, listNavigation]);
    const { isMounted, status: mountedStatus } = useTransitionStatus(context, {
        duration: 100,
    });

    if (selectedCurrency === undefined || currencies.length === 0) {
        return (
            <button
                className={classNames(
                    styles.desktopCurrencySelector,
                    className
                )}
            />
        );
    }

    return (
        <>
            <button
                ref={refs.setReference}
                className={classNames(
                    styles.desktopCurrencySelector,
                    className
                )}
                title="Select currency"
                {...getReferenceProps()}
            >
                <picture className={styles.flag}>
                    <img src={selectedCurrency.flag} alt="" />
                </picture>
                <span>{selectedCurrency.symbol}</span>
            </button>
            {isMounted && (
                <FloatingPortal id={portalId}>
                    <FloatingFocusManager context={context}>
                        <div
                            ref={refs.setFloating}
                            className={styles.popover}
                            style={
                                {
                                    ...floatingStyles,
                                    '--offset': `${topOffset}px`,
                                } as React.CSSProperties
                            }
                            data-status={mountedStatus}
                            {...getFloatingProps()}
                        >
                            <div
                                ref={arrowRef}
                                className={styles.arrow}
                                style={{
                                    left: middlewareData.arrow?.x,
                                    top: middlewareData.arrow?.y,
                                }}
                            />
                            {currencies.map((currency, index) => (
                                <CurrencyOption
                                    key={currency.code}
                                    className={styles.option}
                                    currency={currency}
                                    isSelected={
                                        currency.code === selectedCurrency.code
                                    }
                                    tabIndex={activeIndex === index ? 0 : -1}
                                    ref={(node: HTMLElement) => {
                                        listRef.current[index] = node;
                                    }}
                                    onSelectCurrency={handleSelectCurrency}
                                    {...getItemProps()}
                                />
                            ))}
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );

    function handleOpenChange(open: boolean) {
        if (open) {
            sendEventV2(
                Page.header,
                Area.navigation,
                InteractionItem.currencySelector,
                Action.open,
                null
            );
        }
        setIsOpen(open);
    }

    function handleSelectCurrency(currency: Currency) {
        dispatch(setCurrency(currency.code));
        setIsOpen(false);
        setActiveIndex(null);
        sendEventV2(
            Page.header,
            Area.navigation,
            InteractionItem.currencySelector,
            Action.select,
            {
                currencyCode: currency.code,
            }
        );
    }
}

export default DesktopCurrencySelector;
