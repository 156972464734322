import {
    CloseMobileMenuAction,
    CloseMobileSearchMenuAction,
    RequestLocalisationDataAction,
    RequestSiteDataAction,
    RequestSiteDataFailureAction,
    RequestSiteDataSuccessAction,
    ResetCurrencyAction,
    SetCurrencyAction,
    ToggleMobileMenuAction,
    ToggleMobileSearchMenuAction,
} from '@/redux/actions/site';
import { SetSessionUrlAction } from '@/redux/actions/site/SetSessionUrlAction';

import {
    REQUEST_SITE_DATA,
    REQUEST_SITE_DATA_SUCCESS,
    REQUEST_SITE_DATA_FAILURE,
    SITE_SET_CURRENCY,
    SITE_TOGGLE_MOBILE_MENU,
    SITE_CLOSE_MOBILE_MENU,
    SITE_TOGGLE_MOBILE_SEARCH_MENU,
    SITE_CLOSE_MOBILE_SEARCH_MENU,
    RESET_CURRENCY,
    REQUEST_LOCALISATION_DATA,
    SITE_SET_SESSIONURL,
} from './types';
import * as Cookies from 'js-cookie';

export const requestSiteData = (): RequestSiteDataAction => ({
    type: REQUEST_SITE_DATA,
});

export const requestSiteDataSuccess = (
    data: any
): RequestSiteDataSuccessAction => ({
    type: REQUEST_SITE_DATA_SUCCESS,
    data,
});

export const requestSiteDataFailure = (
    error: string,
    data: any
): RequestSiteDataFailureAction => ({
    type: REQUEST_SITE_DATA_FAILURE,
    error,
    data,
});

export const requestLocalisationData = (): RequestLocalisationDataAction => ({
    type: REQUEST_LOCALISATION_DATA,
});

export const setCurrency = (currencyCode: string): SetCurrencyAction => {
    if (currencyCode) {
        Cookies.set('TAP-SelectedCurrency', currencyCode, {
            expires: 365,
            secure: true,
        });
    }
    return {
        type: SITE_SET_CURRENCY,
        currencyCode: currencyCode,
    };
};

export const setSessionUrl = (sessionUrl: string): SetSessionUrlAction => {
    if (sessionUrl) {
        Cookies.set('TAP-SessionUrl', sessionUrl, { expires: 1, secure: true });
    }
    return {
        type: SITE_SET_SESSIONURL,
        sessionUrl,
    };
};

export const toggleMobileMenu = (): ToggleMobileMenuAction => {
    return {
        type: SITE_TOGGLE_MOBILE_MENU,
    };
};

export const closeMobileMenu = (): CloseMobileMenuAction => {
    return {
        type: SITE_CLOSE_MOBILE_MENU,
    };
};

export const toggleMobileSearchMenu = (): ToggleMobileSearchMenuAction => {
    return {
        type: SITE_TOGGLE_MOBILE_SEARCH_MENU,
    };
};

export const closeMobileSearchMenu = (): CloseMobileSearchMenuAction => {
    return {
        type: SITE_CLOSE_MOBILE_SEARCH_MENU,
    };
};

export const resetCurrency = (): ResetCurrencyAction => {
    return {
        type: RESET_CURRENCY,
    };
};
