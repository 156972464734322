import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import Icon from '../../../common/Icon/Icon';
import Option from './Option/Option';
import { tourSuggestions } from '../../../../data/tourSuggestions';
import { Suggestion, SuggestionType } from '../../../../data';
import { getQueryFromSearchParams } from '../../../../services/tourSearchService';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import {
    getSuggestions,
    Section,
} from '../../../../services/searchSuggestionService';
import styles from './DesktopSearchTool.module.scss';

export interface DesktopSearchToolProps {
    className?: string;
}

export interface TourSearchFieldValue {
    text: string;
    isAnywhere: boolean;
}

const initialValue: TourSearchFieldValue = {
    text: '',
    isAnywhere: false,
};

function DesktopSearchTool({ className }: DesktopSearchToolProps) {
    const history = useHistory();
    const { key: locationKey } = useLocation();
    const [value, setValue] = useState<TourSearchFieldValue>(initialValue);
    const [sections, setSections] = useState<Section[]>([]);
    const popularSuggestions = useMemo<Section[]>(
        () => [
            {
                title: 'Popular',
                suggestions: [...tourSuggestions].filter(s => s.isPopular),
            },
        ],
        []
    );

    // Reset when we change page
    useEffect(() => {
        setValue(initialValue);
    }, [locationKey]);

    return (
        <form
            className={classNames(styles.desktopSearchTool, className)}
            onSubmit={handleSubmit}
        >
            <Autosuggest<Suggestion, Section>
                suggestions={sections}
                multiSection={true}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionSelected={handleSuggestionSelected}
                onSuggestionsClearRequested={clearSuggested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                getSectionSuggestions={getSectionSuggestions}
                renderSectionTitle={renderSectionTitle}
                shouldRenderSuggestions={(_, __) => true}
                focusInputOnSuggestionClick={false}
                theme={styles}
                inputProps={{
                    name: 'text',
                    value: value.isAnywhere ? 'Anywhere' : value.text,
                    onChange: handleInputChange,
                    placeholder: 'Search...',
                }}
            />
            <button
                className={styles.searchButton}
                type="submit"
                aria-label="Search"
            >
                <Icon icon="search" />
            </button>
        </form>
    );

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        redirectToSearch(value);

        sendEventV2(
            Page.header,
            Area.searchWidget,
            InteractionItem.searchBox,
            Action.submit,
            {
                searchText: value.isAnywhere ? 'Anywhere' : value.text,
                attributeData: null,
                dateTimeData: null,
            }
        );
    }

    function redirectToSearch(value: TourSearchFieldValue) {
        const searchParamsString = getQueryFromSearchParams({
            text: value.text,
            isAnywhere: value.isAnywhere,
            source: 'desktop-header',
        });

        history.push({
            pathname: '/search',
            search: `${searchParamsString}`,
        });
    }

    function handleInputChange(
        _: React.FormEvent<HTMLElement>,
        params: Autosuggest.ChangeEvent
    ) {
        setValue({
            text: params.newValue,
            isAnywhere: false,
        });
    }

    function renderSuggestion(suggestion: Suggestion) {
        return <Option value={suggestion} />;
    }

    function handleSuggestionsFetchRequested(
        params: Autosuggest.SuggestionsFetchRequestedParams
    ): void {
        if (params.reason === 'input-focused') {
            setSections(popularSuggestions);
            return;
        }

        if (params.value.trim() !== '') {
            var suggestResults = getSuggestions(params.value);
            setSections(suggestResults);
        }
    }

    function clearSuggested() {
        setSections([]);
    }

    function handleSuggestionSelected(
        _: React.FormEvent<any>,
        data: Autosuggest.SuggestionSelectedEventData<Suggestion>
    ) {
        const { suggestion } = data;
        const selectedValue: TourSearchFieldValue = {
            text:
                suggestion.type !== SuggestionType.Anywhere
                    ? suggestion.name
                    : '',
            isAnywhere: suggestion.type === SuggestionType.Anywhere,
        };
        redirectToSearch(selectedValue);

        sendEventV2(
            Page.header,
            Area.searchWidget,
            InteractionItem.searchBox,
            Action.select,
            {
                searchText: value.isAnywhere ? 'Anywhere' : value.text,
                attributeData: null,
                dateTimeData: null,
            }
        );

        setValue(initialValue);
    }
}

function renderSectionTitle(section: Section) {
    return <h5>{section.title}</h5>;
}

function getSectionSuggestions(section: Section) {
    return section?.suggestions ?? [];
}

function getSuggestionValue(suggestion: Suggestion) {
    return suggestion.name;
}

export default DesktopSearchTool;
