import { useState, useEffect } from 'react';
import debounce from '../utils/debounce';

// These are defined to match the breakdpoints in _media.scss
export const breakpoints = {
    small: 768,
    medium: 1025,
    large: 1200,
};

function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWidth(window.innerWidth);
        }, 100);

        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    });

    return width;
}

export default useWindowWidth;
