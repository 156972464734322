import { Currency } from '@/core';

import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './CurrencyOption.module.scss';

export type CurrencyOptionProps = {
    currency: Currency;
    isSelected: boolean;
    onSelectCurrency: (currency: Currency) => void;
    className?: string;
} & React.HTMLAttributes<HTMLElement>;

const CurrencyOption = forwardRef(function (
    {
        currency,
        isSelected,
        className,
        onSelectCurrency,
        onClick,
        ...props
    }: CurrencyOptionProps,
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <button
            ref={ref as ForwardedRef<HTMLButtonElement>}
            className={classNames(
                styles.currencyOption,
                { [styles.isSelected]: isSelected },
                className
            )}
            onClick={handleClick}
            {...props}
        >
            <picture className={styles.flag}>
                <img src={currency.flag} alt="" />
            </picture>
            <span className={styles.name}>{currency.name}</span>
        </button>
    );

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        onClick?.(e);
        onSelectCurrency(currency);
    }
});

export default CurrencyOption;
