import { useRef, useState } from 'react';
import classNames from 'classnames';
import {
    arrow,
    autoUpdate,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useTransitionStatus,
} from '@floating-ui/react';
import { Link } from 'react-router-dom';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import Icon from '../../../common/Icon/Icon';
import { PrimaryButton } from '../../../common/Button';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { selectTapTelephoneNumber } from '../../../../redux/selectors/site.selectors';
import styles from './DesktopPhoneButton.module.scss';
import AgentList from '../../../common/AgentList/AgentList';

export interface DesktopPhoneButtonProps {
    className?: string;
}

const portalId = 'desktop-links-portal';
const topOffset = 8;

function DesktopPhoneButton({ className }: DesktopPhoneButtonProps) {
    const arrowRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const displayTelephoneNumber = useAppSelector(({ site }) => {
        const { displayTelephoneNumber } = selectTapTelephoneNumber(site);
        return displayTelephoneNumber;
    });
    const telephoneNumber = useAppSelector(({ site }) => {
        const { telephoneNumber } = selectTapTelephoneNumber(site);
        return telephoneNumber;
    });
    const { refs, context, floatingStyles, middlewareData } = useFloating({
        open: isOpen,
        onOpenChange: handleOpenChange,
        strategy: 'absolute',
        placement: 'bottom',
        middleware: [
            offset(topOffset),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const click = useClick(context, {});
    const dismiss = useDismiss(context, {});
    const role = useRole(context, { role: 'dialog' });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role,
    ]);
    const { isMounted, status: mountedStatus } = useTransitionStatus(context, {
        duration: 100,
    });

    return (
        <>
            <CircularIconButton
                ref={refs.setReference}
                className={classNames(styles.desktopPhoneButton, className)}
                icon="phoneSolid"
                as="button"
                aria-label="Open contact us details"
                title="Open contact us details"
                {...getReferenceProps()}
            />
            {isMounted && (
                <FloatingPortal id={portalId}>
                    <FloatingFocusManager context={context}>
                        <div
                            ref={refs.setFloating}
                            className={styles.popover}
                            style={
                                {
                                    ...floatingStyles,
                                    '--offset': `${topOffset}px`,
                                } as React.CSSProperties
                            }
                            data-status={mountedStatus}
                            {...getFloatingProps()}
                        >
                            <div
                                ref={arrowRef}
                                className={styles.arrow}
                                style={{
                                    left: middlewareData.arrow?.x,
                                    top: middlewareData.arrow?.y,
                                }}
                            />
                            <AgentList
                                className={styles.agentList}
                                showAgentImage={true}
                            />
                            <h3>Contact our friendly team!</h3>
                            <p>
                                Give us a call - our team is here to assist you
                                with planning your perfect adventure.
                            </p>
                            <a
                                className={styles.phoneLink}
                                href={`tel:${telephoneNumber}`}
                                onClick={handlePhoneClick}
                            >
                                <Icon icon="phoneSolid" />
                                <span>{displayTelephoneNumber}</span>
                            </a>
                            <PrimaryButton
                                className={styles.enquireButton}
                                as={Link}
                                to="/contact-us"
                                onClick={handleEnquiryClick}
                            >
                                Enquire
                            </PrimaryButton>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );

    function handleOpenChange(open: boolean) {
        if (open) {
            sendEventV2(
                Page.header,
                Area.navigation,
                InteractionItem.phoneLink,
                Action.click,
                null
            );
        }
        setIsOpen(open);
    }

    function handleEnquiryClick() {
        sendEventV2(
            Page.header,
            Area.navigation,
            InteractionItem.enquireNowButton,
            Action.click,
            null
        );
        setIsOpen(false);
    }

    function handlePhoneClick() {
        sendEventV2(
            Page.header,
            Area.phoneNumber,
            InteractionItem.phoneLink,
            Action.click,
            null
        );
    }
}

export default DesktopPhoneButton;
