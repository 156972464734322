import classNames from 'classnames';
import styles from './DesktopLinks.module.scss';
import DealsMenu from './DealsMenu/DealsMenu';
import DestinationsMenu from './DestinationsMenu/DestinationsMenu';
import AdventuresMenu from './AdventuresMenu/AdventuresMenu';

export interface DesktopLinksProps {
    className?: string;
}

function DesktopLinks({ className }: DesktopLinksProps) {
    return (
        <ul className={classNames(styles.desktopLinks, className)}>
            <DealsMenu className={styles.link} />
            <DestinationsMenu className={styles.link} />
            <AdventuresMenu className={styles.link} />
        </ul>
    );
}

export default DesktopLinks;
