import { useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useTransitionStatus,
} from '@floating-ui/react';
import Icon from '../../../common/Icon/Icon';
import CurrencySelector from './CurrencySelector/CurrencySelector';
import { PrimaryButton } from '../../../common/Button';
import PhoneLink from './PhoneLink/PhoneLink';
import MenuItem from './MenuItem/MenuItem';
import { useHeader } from '../HeaderContext.v2';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import logoImage from '../../../../assets/images/the-adventure-people-logo-full.svg';
import { mobileHeight } from '../Header.v2.constants';
import styles from './MobileMenu.module.scss';

function MobileMenu() {
    const windowWidth = useWindowWidth();
    const { isMobileMenuOpen, setIsMobileMenuOpen } = useHeader();
    const { refs, context, floatingStyles } = useFloating({
        open: isMobileMenuOpen,
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'menu' });
    const { getFloatingProps } = useInteractions([click, dismiss, role]);
    const { isMounted, status: mountedStatus } = useTransitionStatus(context, {
        duration: 100,
    });

    useEffect(() => {
        if (windowWidth > 1024) {
            setIsMobileMenuOpen(false);
        }
    }, [windowWidth, setIsMobileMenuOpen]);

    return (
        <FloatingPortal id="mobile-menu">
            {isMounted && (
                <>
                    <FloatingOverlay
                        className={styles.overlay}
                        lockScroll={true}
                    />
                    <FloatingFocusManager context={context}>
                        <div
                            ref={refs.setFloating}
                            className={styles.mobileMenu}
                            style={
                                {
                                    '--mobile-height': `${mobileHeight}px`,
                                    ...floatingStyles,
                                } as React.CSSProperties
                            }
                            data-status={mountedStatus}
                            {...getFloatingProps()}
                        >
                            <nav className={styles.header}>
                                <Link
                                    to="/"
                                    className={styles.home}
                                    onClick={handleBrandClick}
                                >
                                    <img
                                        loading="eager"
                                        fetchpriority="high"
                                        alt="Home"
                                        src={logoImage}
                                    />
                                </Link>
                                <button
                                    className={styles.closeButton}
                                    onClick={handleClose}
                                >
                                    <Icon icon="x" />
                                </button>
                            </nav>
                            <div
                                className={classNames(
                                    styles.menuGroup,
                                    styles.links
                                )}
                            >
                                <MenuItem
                                    icon="deals"
                                    url="/deals"
                                    links={['/deals']}
                                >
                                    Deals
                                </MenuItem>
                                <MenuItem
                                    icon="mapMarker"
                                    url="/destinations"
                                    links={['/destinations']}
                                >
                                    Destinations
                                </MenuItem>
                                <MenuItem
                                    icon="personHiking"
                                    url="/activities"
                                    links={['/activities']}
                                >
                                    Adventures
                                </MenuItem>
                            </div>
                            <div
                                className={classNames(
                                    styles.menuGroup,
                                    styles.user
                                )}
                            >
                                <MenuItem
                                    icon="userEmpty"
                                    as={Link}
                                    to="/myaccount"
                                >
                                    My Account
                                </MenuItem>
                                <MenuItem
                                    icon="heartLight"
                                    as={Link}
                                    to="/favourites"
                                >
                                    Favourites
                                </MenuItem>
                                <CurrencySelector
                                    className={styles.currencySelector}
                                />
                            </div>
                            <div
                                className={classNames(
                                    styles.menuGroup,
                                    styles.contact
                                )}
                            >
                                <PhoneLink className={styles.phoneLink} />
                                <PrimaryButton
                                    className={styles.enquireButton}
                                    as={Link}
                                    to="/contact-us"
                                    onClick={handleEnquire}
                                >
                                    Enquire
                                </PrimaryButton>
                            </div>
                        </div>
                    </FloatingFocusManager>
                </>
            )}
        </FloatingPortal>
    );

    function handleClose() {
        setIsMobileMenuOpen(false);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.closeButton,
            Action.click,
            null
        );
    }

    function handleEnquire() {
        setIsMobileMenuOpen(false);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.enquireButton,
            Action.click,
            null
        );
    }

    function handleBrandClick() {
        setIsMobileMenuOpen(false);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.homeLink,
            Action.click,
            null
        );
    }
}

export default MobileMenu;
