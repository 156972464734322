import { SiteData } from '@/apis/contentApi';
import { BasicApiResponse } from '@/endurance';
import { SiteDataResult } from '@/endurance/content';

import { cosmicSiteSlug } from '../utils/config';
import { getResponse } from './enduranceApi';

export async function getSiteData(): Promise<SiteData> {
    return await getResponse<BasicApiResponse<SiteDataResult>>(
        `/v2/content/siteData?slug=${cosmicSiteSlug}`
    ).then(({ result, errors }) => {
        if (errors && errors.length > 0) {
            throw new Error(`Get Site Data Request Error: ${errors}`);
        } else if (!result) {
            throw new Error('Site Data Not Found');
        }

        const returnData: SiteData = {
            header: {
                dealsMenuTitle: result.dealsMenuTitle,
                dealsMenuUrl: result.dealsMenuUrl,
                dealsLinks: result.dealsLinks.map(
                    ({ title, slug, imageUrl }) => ({
                        title,
                        slug,
                        imageUrl,
                    })
                ),
            },
            dealsImageUrl: result.dealsImageUrl,
            saleBanner: result.saleBannerTitle
                ? {
                      title: result.saleBannerTitle,
                      imageUrl: result.saleBannerImageUrl,
                      linkText: result.saleBannerLinkText,
                      linkUrl: result.saleBannerLinkUrl,
                  }
                : null,
            reviews: result.reviews.map(review => ({
                title: review.title,
                rating: review.rating,
                text: review.text,
                author: review.author,
                date: review.date,
            })),
            regions: result.regions.map(region => ({
                title: region.title,
                slug: region.slug,
                imageUrl: region.imageUrl,
                destinations: region.destinations.map(destination => ({
                    title: destination.title,
                    slug: destination.slug,
                    isPopular: destination.isPopular,
                    hideOnDestinationPage: destination.hideOnDestinationPage,
                    imageUrl: destination.imageUrl,
                })),
            })),
            popularDestinationsImageUrl: result.popularDestinationsImageUrl,
            activities: result.activities.map(activity => ({
                title: activity.title,
                slug: activity.slug,
                type: activity.type,
                isPopular: activity.isPopular,
                isIconic: activity.isIconic,
                imageUrl: activity.imageUrl,
            })),
            popularActivitiesImageUrl: result.popularActivitiesImageUrl,
            currencies: result.currencies
                .filter(({ enabled }) => {
                    return enabled;
                })
                .map(currency => ({
                    code: currency.code,
                    symbol: currency.symbol,
                    position: currency.position,
                    space: currency.space,
                    name: currency.name,
                    flag: currency.flag,
                    displayTelephoneNumber: currency.displayTelephoneNumber,
                    telephoneNumber: currency.telephoneNumber,
                    telephoneNumberTailormade: null,
                    displayTailorMadeTelephoneNumber: null,
                    locale: currency.locale,
                })),
            tailorMadeImageUrl: result.tailorMadeImageUrl,
            agentHeroImageUrl: result.agentHeroImageUrl,
            agentImageUrls: result.agentImageUrls,
        };

        return returnData;
    });
}
