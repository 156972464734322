import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../../../../../../common/Icon/Icon';
import { useDesktopMenu } from '../../DesktopMenuContext';
import styles from './MenuItem.module.scss';

export type MenuItemProps = {
    title: string;
    url: string;
    isLink: boolean;
    isSelected: boolean;
    className?: string;
} & React.HTMLAttributes<HTMLElement>;

const MenuItem = forwardRef(function (
    {
        title,
        url,
        isLink,
        isSelected,
        className,
        onClick,
        ...props
    }: MenuItemProps,
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <BaseItem
            ref={ref}
            className={classNames(
                styles.menuItem,
                { [styles.isSelected]: isSelected && !isLink },
                { [styles.isLink]: isLink },
                className
            )}
            url={url}
            isLink={isLink}
            {...props}
        >
            <span>{title}</span>
            <Icon icon="chevron" />
        </BaseItem>
    );
});

const BaseItem = forwardRef(function (
    {
        url,
        isLink,
        onClick,
        ...props
    }: {
        url: string;
        isLink: boolean;
    } & React.HTMLAttributes<HTMLElement>,
    ref: ForwardedRef<HTMLElement>
) {
    const { closeMenu } = useDesktopMenu();
    return isLink ? (
        <Link
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            to={url}
            role="link"
            onClick={handleLinkClick}
            {...props}
        />
    ) : (
        <button
            role="menu"
            ref={ref as ForwardedRef<HTMLButtonElement>}
            onClick={onClick}
            {...props}
        />
    );

    function handleLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
        if (onClick) {
            onClick(e);
        }
        closeMenu();
    }
});

export default MenuItem;
