import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { fetchAgentsRequest } from '../../../redux/actions/agents.actions';
import { shuffle } from '../../../helpers/utils/shuffle';
import styles from './AgentList.module.scss';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { AgentDetail } from '@/endurance/agents';

export interface AgentListProps {
    showAgentName?: boolean;
    showAgentImage?: boolean;
    showAgentEmail?: boolean;
    showAgentExpertCountries?: boolean;
    showAgentExpertCountriesSummary?: boolean;
    loading?: 'lazy' | 'eager';
    className?: string;
}

const AgentList = ({
    showAgentName = false,
    showAgentImage = false,
    showAgentEmail = false,
    showAgentExpertCountries = false,
    showAgentExpertCountriesSummary = false,
    loading,
    className,
}: AgentListProps) => {
    const dispatch = useAppDispatch();
    const { agents, loading: agentsLoading } = useAppSelector(
        state => state.agents
    );

    useEffect(() => {
        if (agentsLoading === null) {
            dispatch(fetchAgentsRequest());
        }
    }, [agentsLoading, dispatch]);

    // Shuffle and slice the first 3 agents
    const shuffledAgents: AgentDetail[] = useMemo(() => {
        if (agents === null) {
            return [];
        }
        const agentsWithImages = agents.filter(agent => agent.image); // Filter agents with images
        return shuffle(agentsWithImages).slice(0, 3);
    }, [agents]);

    if ((agentsLoading ?? true) || agents === null) {
        return null;
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <ul>
                {shuffledAgents.map((agent, index) => {
                    const shouldRenderLi =
                        (showAgentName ||
                            showAgentImage ||
                            showAgentEmail ||
                            showAgentExpertCountriesSummary ||
                            showAgentExpertCountries) &&
                        agent.image;

                    return (
                        shouldRenderLi && (
                            <li key={index}>
                                {showAgentImage && agent.image && (
                                    <img
                                        src={getImageUrl(
                                            agent.image as string,
                                            150,
                                            150
                                        )}
                                        alt={`${agent.firstName}'s profile`}
                                        loading={loading}
                                    />
                                )}
                                {showAgentName && <h4>{agent.firstName}</h4>}
                                {showAgentEmail && <p>Email: {agent.email}</p>}
                                {showAgentExpertCountriesSummary && (
                                    <span>
                                        Southeast Asia & Scandinavia Specialist
                                    </span>
                                )}
                                {showAgentExpertCountries && (
                                    <>
                                        <p>Expert Countries:</p>
                                        <ul>
                                            {agent.expertCountries.map(
                                                (country, i) => (
                                                    <li key={i}>{country}</li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                )}
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
};

function getImageUrl(url: string, width: number, height: number) {
    return url
        .replace('{width}', width.toString())
        .replace('{height}', height.toString());
}

export default AgentList;
