import {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ElementType,
    ForwardedRef,
    forwardRef,
    RefAttributes,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import Icon, { Icons } from '../Icon/Icon';
import styles from './CircularIconButton.module.scss';

export type CircularIconButtonProps =
    | HtmlButtonProps
    | HtmlAnchorProps
    | ReactRouterDomLinkProps
    | HtmlDivProps;

function CircularIconButton(
    { as, className, icon, ...props }: CircularIconButtonProps,
    ref: ForwardedRef<HTMLElement>
) {
    const Component = (as ?? 'button') as ElementType;
    return (
        <Component
            className={classNames(styles.circularIconButton, className)}
            ref={ref}
            {...props}
        >
            <Icon className={styles.icon} icon={icon} />
        </Component>
    );
}

export type CircularIconButtonBase<
    C extends 'button' | 'a' | typeof Link | 'div',
> = {
    as?: C;
    icon: keyof Icons;
};

export type HtmlButtonProps = CircularIconButtonBase<'button'> &
    RefAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLButtonElement>;
export type HtmlAnchorProps = CircularIconButtonBase<'a'> &
    RefAttributes<HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>;
export type ReactRouterDomLinkProps = CircularIconButtonBase<typeof Link> &
    LinkProps;
export type HtmlDivProps = CircularIconButtonBase<'div'> &
    RefAttributes<HTMLDivElement> &
    AnchorHTMLAttributes<HTMLDivElement>;

export default forwardRef(CircularIconButton);
