import {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ElementType,
    ReactNode,
    RefAttributes,
    SyntheticEvent,
} from 'react';
import classNames from 'classnames';
import Icon, { Icons } from '../../../../common/Icon/Icon';
import styles from './MenuItem.module.scss';
import { Link, LinkProps } from 'react-router-dom';
import { useHeader } from '../../HeaderContext.v2';

export type MenuItemProps =
    | HtmlButtonProps
    | HtmlAnchorProps
    | ReactRouterDomLinkProps;

function MenuItem({
    as,
    icon,
    children,
    links,
    onClick,
    className,
    ...props
}: MenuItemProps) {
    const { setIsMobileMenuOpen } = useHeader();
    const Component = (as ?? 'button') as ElementType;
    const hasSubMenu = (links?.length ?? 0) > 0;
    return (
        <Component
            className={classNames(styles.menuItem, className)}
            onClick={handleClick}
            {...props}
        >
            <Icon className={styles.icon} icon={icon} />
            <span className={styles.children}>{children}</span>
            {hasSubMenu && <Icon className={styles.chevron} icon="chevron" />}
        </Component>
    );

    function handleClick(e: SyntheticEvent) {
        onClick?.(e);
        if (!hasSubMenu) {
            setIsMobileMenuOpen(false);
        }
    }
}

interface MenuItemBaseProps<C extends 'button' | 'a' | typeof Link> {
    icon: keyof Icons;
    children: ReactNode;
    links?: [string];
    onClick?: (e: SyntheticEvent) => void;
    as?: C;
}

export type HtmlButtonProps = MenuItemBaseProps<'button'> &
    RefAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLButtonElement> & {
        url: string;
    };
export type HtmlAnchorProps = MenuItemBaseProps<'a'> &
    RefAttributes<HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>;
export type ReactRouterDomLinkProps = MenuItemBaseProps<typeof Link> &
    LinkProps;

export default MenuItem;
