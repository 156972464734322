import { AgentDetail } from '@/endurance/agents';
import {
    FETCH_AGENTS_REQUEST,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_FAILURE,
} from '../actions/types';
import {
    FetchAgentFailureAction,
    FetchAgentRequestAction,
    FetchAgentSuccessAction,
} from '@/redux/actions/agents';

export const fetchAgentsRequest = (): FetchAgentRequestAction => {
    return {
        type: FETCH_AGENTS_REQUEST,
    };
};

export const fetchAgentsSuccess = (
    agents: AgentDetail[]
): FetchAgentSuccessAction => ({
    type: FETCH_AGENTS_SUCCESS,
    agents: agents,
});

export const fetchAgentsFailure = (error: string): FetchAgentFailureAction => ({
    type: FETCH_AGENTS_FAILURE,
    error: error,
});
